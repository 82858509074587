


































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import ManagementLayout from '@/layouts/management/ManagementLayout.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import ManagementList from '@/components/management/ManagementList.vue';
import { CourseListItem, MultiListItem, SubjectLevelListItem } from '@/models/management';
import { SubjectStores } from '@/store/subjects/index';
import { FormStores } from '@/store/forms/index';
import { CourseStores } from '@/store/course/index';
import ManagementItemFormTemplate from '@/components/management/ManagementItemFormTemplate.vue';
import { FolderItem } from '@/models/miscellaneous';
import moment from 'moment';
import { PaginationDetails } from '@/models/generic';
import { FetchCoursesWithParams } from '@/models/courses';
import { FormItemSearchParams } from '@/models/forms';
import { SubjectItemSearchParams } from '@/models/subjects';
@Component({
  components: {
    Page,
    ManagementLayout,
    SearchBar,
    ManagementList,
    ManagementItemFormTemplate,
  },
})
export default class Courses extends Vue {
  currentFolders: FolderItem[] = [];
  currentCourses: CourseListItem[] = [];
  currentForms: SubjectLevelListItem[] = [];
  currentSubjects: SubjectLevelListItem[] = [];
  isCreateFlowActive = false;
  isDeleteButtonDisabled = true;
  subjectCreateStore = SubjectStores.create;
  subjectDetailStore = SubjectStores.details;
  subjectListStore = SubjectStores.list;
  formCreateStore = FormStores.create;
  formDetailStore = FormStores.details;
  formListStore = FormStores.list;
  courseListStore = CourseStores.list;
  courseDetailStore = CourseStores.details;

  // State Store
  courseStateStore = CourseStores.state;

  // Checkbox Selection
  subjectCheckboxes: number[] = [];
  formCheckboxes: number[] = [];
  courseCheckboxes: number[] = [];

  // Pagination 
  currentPage = 1;
  currentLimit = 10;
  currentTotalCount = 10;
  currentKeyword = ``;
  isPaginationReset = false;
  isPageSpecified = false;

  get folderNames() {
    return this.currentFolders;
  }

  get subjects() {
    return this.currentSubjects;
  }

  get forms() {
    return this.currentForms;
  }

  get courses() {
    return this.currentCourses;
  }

  get formErrorText() {
    return `Error: Level name cannot include any special characters. Example: !, @, #, $, %, ^, &, *`;
  }

  mounted() {
    if (this.courseStateStore.courseFolderState.length > 0 && this.courseStateStore.isModified) {
      // Refetch state from API depending on the previous folder state
      this.currentFolders = this.courseStateStore.courseFolderState;
      this.courseStateStore.updateCourseIsModified(false);
      switch(this.currentFolders.length) {
        case 2: {
          // Containing Subjects and Subject Name. Fetch Subjects and levels
          this.currentSubjects = this.courseStateStore.subjectData;
          break;
        }
        case 3: {
          // Containing Subjects, Subject name and Level name
          this.currentSubjects = this.courseStateStore.subjectData;
          this.currentForms = this.courseStateStore.formData;
          break;
        }
        default: {
          break;
        }
      }
      this.retrieveData();
    } else if (this.courseStateStore.courseFolderState.length > 0 && !this.courseStateStore.isModified) {
        // Get back previous states from stores
        this.currentFolders = this.courseStateStore.courseFolderState;
        this.currentSubjects = this.courseStateStore.subjectData;
        this.currentForms = this.courseStateStore.formData;
        this.currentCourses = this.courseStateStore.courseData;
        this.currentPage = this.courseStateStore.currentPageState[this.currentFolders.length];
        this.currentLimit = this.courseStateStore.currentLimitState[this.currentFolders.length];
        this.currentTotalCount = this.courseStateStore.currentTotalCountState[this.currentFolders.length];
        this.currentKeyword = this.courseStateStore.currentKeywordState[this.currentFolders.length];
        this.isPageSpecified = true;
    } else {
      // Base State
      this.currentFolders.push({id: -1, name: `Subjects`});
      this.retrieveData();
    }
  }

  beforeDestroy() {
    this.saveCurrentState();
  }

  // Pagination
  updatePagination(payload: PaginationDetails) {
    this.currentPage = payload.page;
    this.currentLimit = payload.limit;
    this.retrieveData();
  }

  resetPaginationStatus(payload: boolean) {
      this.isPaginationReset = payload;
    }

  resetCurrentPageStatus(payload: boolean) {
    this.isPageSpecified = payload;
  }

  resetFolders(payload: FolderItem[]) {
    this.currentFolders = payload;
    this.currentPage = this.courseStateStore.currentPageState[this.currentFolders.length];
    this.currentLimit = this.courseStateStore.currentLimitState[this.currentFolders.length];
    this.currentKeyword = this.courseStateStore.currentKeywordState[this.currentFolders.length];
    this.isPageSpecified = true;
    this.saveCurrentState();
    this.retrieveData();
  }

  retrieveData() {
    switch(this.currentFolders.length) {
        case 1: {
          // Base State: Containing Subjects only. Fetch Subjects only
          const payload: Partial<SubjectItemSearchParams> = {
            page: this.currentPage,
            limit: this.currentLimit,
          };
          if (this.currentKeyword.length > 0) {
            payload.keyword = this.currentKeyword;
          }
          this.subjectListStore.retrieveSubjects(payload);
          break;
        }
        case 2: {
          // Containing Subjects and Subject Name. Fetch Subjects and levels
          const payload: Partial<FormItemSearchParams> = {
              subjectid: this.currentFolders[this.currentFolders.length - 1].id
          };
          this.formListStore.retrieveForms(payload);
          break;
        }
        case 3: {
          // Containing Subjects, Subject name and Level name
          const payload: Partial<FetchCoursesWithParams> = {
            page: this.currentPage,
            limit: this.currentLimit,
            form: this.currentFolders[this.currentFolders.length - 1].name,
            subject: this.currentFolders[this.currentFolders.length - 2].id,
          };
          if (this.currentKeyword.length > 0) {
            payload.keyword = this.currentKeyword;
          }
          this.courseListStore.retrieveCoursesWithParams(payload);
          break;
        }
        default: {
          break;
        }
      }
  }

  @Watch(`subjectListStore.response`)
  setSubjects() {
    if (this.subjectListStore.response) {
      this.currentSubjects = [];
      this.currentTotalCount = this.subjectListStore.response.totalCount;
      this.subjectListStore.response.objects.forEach((data) => {
        this.currentSubjects.push({
          id: data.id,
          name: data.subjectName,
          numberOfCourses: data.numberOfCourses, // Figure this out
        });
      });
      this.courseStateStore.updateCourseSubjectState(this.currentSubjects);
    }
  }

  @Watch(`formListStore.response`)
  setForms() {
    if (this.formListStore.response) {
      this.currentForms = [];
      this.currentTotalCount = 0;
      this.formListStore.response.objects.forEach((data) => {
        this.currentForms.push({
          id: data.id,
          name: data.formName,
          numberOfCourses: data.numberOfCourses,
        });
      });
      this.courseStateStore.updateCourseFormState(this.currentForms);
    }
  }

  @Watch(`courseListStore.response`)
  setCourses() {
    if (this.courseListStore.response) {
      this.currentCourses = [];
      this.currentTotalCount = this.courseListStore.response.totalCount;
      this.courseListStore.response.objects.forEach((data) => {
        this.currentCourses.push({
          id: data.id,
          courseId: data.courseId,
          name: `${data.courseName} - ${data.form.name} - ${moment(data.courseStartTime).format(`YYYY`)} - ${data.teacher.firstname} ${data.teacher.lastname}`,
          numberOfCourses: -1,
        });
      });
      this.courseStateStore.updateCoursesState(this.currentCourses);
    }
  }

  redirectToCreateCourses() {
    this.saveCurrentState();
    this.$router.push({
      path: '/courses/create'
    });
  }

  redirectToEditCourse(item: MultiListItem) {
    // Save State
    this.saveCurrentState();
    this.$router.push({
      path: `/courses/${item.id}/edit`,
    });
  }

  // Create Functions
  createSubject(payload: string) {
    this.subjectCreateStore.createNewSubject(payload);
    this.isCreateFlowActive = false;
  }

  createLevel(payload: string) {
    // Find the current level
    const currentSubject = this.folderNames[this.folderNames.length - 1];
    this.formCreateStore.createNewForm({subjectId: currentSubject.id, formName: payload});
    this.isCreateFlowActive = false;
  }

  @Watch(`subjectCreateStore.response`)
  @Watch(`subjectDetailStore.response`)
  updateCurrentList() {
    /*
      Create Response contains data
      Update / Delete Response does not contain data
    */
    if (this.subjectCreateStore.response || this.subjectDetailStore.response) {
      this.isCreateFlowActive = false;
      this.subjectListStore.retrieveSubjects({ page: this.currentPage, limit: this.currentLimit });
    }
  }

  @Watch(`formCreateStore.response`)
  @Watch(`formDetailStore.response`)
  updateCurrentFormList() {
    /*
      Create Response contains data
      Update / Delete Response does not contain data
    */
    if (this.formCreateStore.response || this.formDetailStore.response) {
      this.isCreateFlowActive = false;
      this.formListStore.retrieveForms({
        page: this.currentPage,
        limit: this.currentLimit,
        subjectid: this.currentFolders[this.currentFolders.length - 1].id
      });
    }
  }

  @Watch(`courseDetailStore.response`)
  updateCurrentCourseList() {
    // Delete Response does not contain data
    if (this.courseDetailStore.response) {
      this.courseListStore.retrieveCoursesWithParams({ 
        form: this.folderNames[this.folderNames.length - 1].name, 
        subject: this.folderNames[this.folderNames.length - 2].id
      });
    }
  }

  // Edit Functions
  updateSelectedSubject(payload: CourseListItem) {
    this.subjectDetailStore.updateCurrentSubject({ id: payload.id, subjectName: payload.name });
  }

  updateSelectedLevel(payload: CourseListItem) {
    this.formDetailStore.updateCurrentForm({ id: payload.id, formName: payload.name });
  }

  // Delete Functions
  deleteSelectedSubject(payload: CourseListItem) {
    this.subjectDetailStore.deleteCurrentSubject({id: payload.id, subjectName: payload.name });
  }

  deleteSelectedLevel(payload: CourseListItem) {
    this.formDetailStore.deleteCurrentForm({ id: payload.id, formName: payload.name });
  }

  deleteSelectedCourse(payload: CourseListItem) {
    this.courseDetailStore.deleteCurrentCourse(payload.id);
  }

  // Folder Route function
  addFolderRouteForSubjects(payload: CourseListItem) {
    this.saveCurrentState();
    this.folderNames.push({...payload});
    this.subjectCheckboxes = [];
    this.formCheckboxes = [];
    this.courseCheckboxes = [];
    this.currentKeyword = ``;
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.isCreateFlowActive = false;
    this.retrieveData();
  }

  addFolderRouteForForms(payload: CourseListItem) {
    this.saveCurrentState();
    this.folderNames.push({...payload});
    this.subjectCheckboxes = [];
    this.formCheckboxes = [];
    this.courseCheckboxes = [];
    this.currentKeyword = ``;
    this.currentPage = 1;
    this.isPaginationReset = true;
    // We know that the 2nd folder is the subject
    this.isCreateFlowActive = false;
    this.retrieveData();
  }

  // Checkbox
  deleteSelectedSubjects(payload: number[]) {
    this.subjectCheckboxes = payload;
    this.isDeleteButtonDisabled = this.subjectCheckboxes.length > 0 ? false: true;
  }

  deleteSelectedForms(payload: number[]) {
    this.formCheckboxes = payload;
    this.isDeleteButtonDisabled = this.formCheckboxes.length > 0 ? false: true;
  }

  deleteSelectedCourses(payload: number[]) {
    this.courseCheckboxes = payload;
    this.isDeleteButtonDisabled = this.courseCheckboxes.length > 0 ? false: true;
  }

  deleteSelectedItems() { 
    switch(this.currentFolders.length) {
      case 2: {
        // Delete Multiple Subjects
        break;
      }
      case 3: {
        // Delete Multiple Forms
        break;
      }
      case 4: {
        // Delete multiple courses
        break;
      }
      default: {
        break;
      }
    }
  }

  // Search
  searchItem(payload: string) {
    // Search based on Folder Levels
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.currentKeyword = payload;
    this.retrieveData();
  }

  // State
  saveCurrentState() {
    this.courseStateStore.saveCourseFolderState(this.currentFolders);
    this.courseStateStore.saveCourseFormState(this.currentForms);
    this.courseStateStore.saveCoursesState(this.currentCourses);
    this.courseStateStore.saveCourseSubjectState(this.currentSubjects);
    this.courseStateStore.saveCourseCurrentPage({ folderLevel: this.currentFolders.length, value: this.currentPage});
    this.courseStateStore.saveCourseCurrentLimit({ folderLevel: this.currentFolders.length, value: this.currentLimit});
    this.courseStateStore.saveCourseCurrentTotalCount({ folderLevel: this.currentFolders.length, value: this.currentTotalCount});
    this.courseStateStore.saveCourseCurrentKeyword({ folderLevel: this.currentFolders.length, value: this.currentKeyword});
  }
}
